<template>
    <div>
        
            <div
                v-for="item of validItems"
                :key="item.type"
                class="flex item"
                :class="{ active: item.type === currentType }"
                @click="setType(item.type)"
            >
                {{ item.label }}
            </div>
        

        
    </div>
</template>

<script>
import { relationTypes } from '../../js/constants.js';

export default {
    name: 'RelationTab',
    props: {
        idType: {
            type: Number,
            default: 1,
        },

       

        
    },

    data() {
        return {
            items: relationTypes,
            currentType: 1
        };
    },

    computed: {
        /**
         * 获取合法的元素列表
         * @return {Array<{ type: string, label: string }>}
         */
        validItems() {
            return this.items;
        },
    },

    mounted() {
        this.currentType = this.idType
    },

    methods: {
       

        setType(type) {
            
            this.currentType = type;
            this.$emit('change', type);
        }
    },
};
</script>

<style scoped>
.item {
    color: #999;
    font-size: 12px;
    border-radius: 4px;
    padding: 8px 12px;
    border: 1px solid #e5e5e5;
    display: inline-block;
    margin-right: 12px;
}

.item.active {
    background: #059eff;
    border-color: #059eff;
    color: #fff;
}
</style>
