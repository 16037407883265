<template>
    <main class="tl pt-35 pb-70" v-if="inited">
        <transition name="fade">
            <!-- 当尚未选择关系时出现 -->
            <div
                class="fixed absolute-fill"
                v-if="payee_term === '' && !isEdit"
                key="init-page"
            >
                <h1 class="ph4 f-34 lh-title">
                    请先选择收款方式
                </h1>
                <div class="ph4 mt5">
                    <RelationPanel
                        :idType="payee_term"
                        @change="onTypeChange"
                    />
                </div>
            </div>
            <!-- 当已经选择关系时出现 -->
            <div v-else key="form-page">
                <h1 class="ph4 f-34 lh-title">收款人信息</h1>

                <section class="ph5 mt-45">
                    <div class="f5 b">收款方式</div>
                    <div class="pv5 bb b--light-gray">
                        <RelationTab
                            
                            :idType="payee_term"
                            :idTypeAndRelationDisagree="idTypeAndRelationDisagree"
                            @change="onTypeChange"
                        />
                    </div>
                </section>

                <!-- 真实姓名 -->
                <section class="ph5 mt5" v-if="payee_term == 1">
                    <div class="f5 b">真实姓名</div>
                    <div class="pv5 bb b--light-gray">
                        <input
                            v-position
                            v-model="payee_name"
                            type="text"
                            key="payee_name"
                            class="w-100 bn f5 lh-solid pl-0"
                            placeholder="填写收款人真实姓名"
                            maxlength="30"
                            :class="{ disable: !inputIsEdit }"
                            @input="userNameChange"
                        />
                    </div>
                </section>
                <section class="ph5 mt5" v-if="payee_term == 2">
                    <div class="f5 b">对公账户开户名称</div>
                    <div class="pv5 bb b--light-gray">
                        <input
                            v-position
                            v-model="payee_name"
                            type="text"
                            key="payee_name"
                            class="w-100 bn f5 lh-solid pl-0"
                            placeholder="填写对公账户名称"
                            maxlength="30"
                            :class="{ disable: !inputIsEdit }"
                            @input="userNameChange"
                        />
                    </div>
                </section>

                <!-- 身份证号 -->
                <section class="ph5 mt5" v-if="showCardNo">
                    <div class="f5 b">身份证号</div>
                    <div class="pv5 bb b--light-gray">
                        <input
                            v-position
                            v-model="card_no"
                            type="text"
                            key="card_no"
                            class="w-100 bn f5 lh-solid pl-0"
                            placeholder="填写收款人身份证号"
                            maxlength="18"
                        />
                    </div>
                </section>

                <!-- 手机号 -->
                <section class="ph5 mt5" v-if="payee_term == 1">
                    <div class="f5 b">手机号</div>
                    <div class="pv5 bb b--light-gray">
                        <input
                            v-position
                            v-model="phone"
                            type="tel"
                            key="phone"
                            class="w-100 bn f5 lh-solid pl-0"
                            placeholder="填写收款人手机号码"
                            maxlength="11"
                        />
                    </div>
                </section>


                

                <!-- 银行卡 -->
                <section class="ph5 mt5">
                    <div class="flex items-center">
                        <div class="f5 b w-4em">银行卡</div>
                        <div class="ml-40">
                            <BankPicker
                                :bankType="payee_location_code"
                                :banks="allBanks"
                                class="mt-28"
                                @change="onBankTypeChange"
                            />
                        </div>
                    </div>
                    <div class="pv5 bb b--light-gray">
                        <input v-if="payee_term == 1"
                            v-position
                            type="text"
                            :value="payee_account_display"
                            @input="onPayeeAccountInput"
                            key="payee_account"
                            class="w-100 bn f5 lh-solid pl-0"
                            placeholder="填写收款人银行卡号"
                            maxlength="30"
                        />
                        <input v-if="payee_term == 2"
                            v-position
                            type="text"
                            :value="payee_account_display"
                            @input="onPayeeAccountInput"
                            key="payee_account"
                            class="w-100 bn f5 lh-solid pl-0"
                            placeholder="填写对公账户的银行卡号"
                            maxlength="30"
                        />
                    </div>
                </section>

                <!-- 开户支行 -->
                <section class="ph5 mt5">
                    <div class="flex items-center">
                        <div class="f5 b w-4em">开户支行</div>
                        <div class="ml-40">
                            <AddressPicker
                                :province="bank_loc.province"
                                :city="bank_loc.city"
                                class="mt-12"
                                @change="onAddressChange"
                            />
                        </div>
                    </div>
                    <div class="pv5 bb b--light-gray">
                        <input
                            v-position
                            v-model="payee_location_branch"
                            type="text"
                            key="payee_location_branch"
                            class="w-100 bn f5 lh-solid pl-0"
                            placeholder="请填写准确，可拨打银行电话咨询"
                            maxlength="50"
                        />
                    </div>
                    <div class="silver f7 mt5">
                        绑卡须知：平台只支持储蓄卡提现，请勿绑定信用卡、存折等以免影响使用。
                    </div>
                </section>

                <!-- 收款人手持身份证照片 -->
                <section class="ph5 mt5" v-if="payee_term == 1">
                    <div class="f5 b">
                        上传收款人手持身份证照片（必填）
                    </div>
                    <div class="pv5">
                        <uploader
                            label="身份证"
                            :img="card_img[0].img"
                            key="uploader-card_img"
                            class="w-31"
                            @success="onUploadImg($event, card_img[0])"
                            @delete="onDeleteImg(card_img[0])"
                        />
                    </div>
                    <div class="silver f7">
                        身份证上的所有信息必须清晰可见，必须能看清身份证号
                    </div>
                </section>

                <section class="ph5 mt5" v-if="payee_term == 2">
                    <div class="f5 b">
                        上传社会组织法人登记证书/营业执照（必填）
                    </div>
                    <div class="pv5">
                        <uploader
                            label="上传"
                            :img="card_img[0].img"
                            key="uploader-card_img"
                            class="w-31"
                            @success="onUploadImg($event, card_img[0])"
                            @delete="onDeleteImg(card_img[0])"
                        />
                    </div>
                    <div class="silver f7">
                        上传的所有信息必须清晰可见
                    </div>
                </section>

                <section class="ph5 mt5">
                    <div class="f5 b">其他医疗材料（选填）</div>
                    <div class="pv5 flex justify-between flex-wrap">
                        <uploader
                            v-for="(item, index) of medical_img"
                            :key="'medical_img-' + item.type"
                            :label="item.label"
                            :img="item.img"
                            class="w-31"
                            @success="onUploadImg($event, medical_img[index])"
                            @delete="onDeleteImg(medical_img[index])"
                        />
                    </div>
                    <div class="silver f7">
                        材料上的所有信息必须清晰可见
                    </div>
                </section>

                <div class="f-13 moon-gray tc mt7">以上所有信息仅用于审核</div>

                <div class="fixed bottom-0 left-0 right-0" id="footerBar">
                    <mmcButton class="b" @click="onSubmit">
                        保存
                    </mmcButton>
                </div>
            </div>
        </transition>
    </main>
</template>

<script>
// @ts-check
import store from 'store';
import RelationPanel from './components/RelationPanel/index.vue';
import RelationTab from './components/RelationTab/index.vue';
import AddressPicker from '../common/components/AddressPicker/index.vue';
import BankPicker from '../common/components/BankPicker/index.vue';
import Uploader from '../common/components/Uploader/index.vue';
import mmcButton from '@/components/mmcUI/mmc-btn-big.vue';
import utils from '@/common/js/utils.js';
import {
    debounce,
    registerDirectives,
    replacetStrTrimAll,
 } from '../common/js/utils.js';
import {
    getPayeeInfo,
    submitPayeeInfo,
    getBankList,
} from '../common/js/api.js';
import { formatCardList } from './js/utils.js';
import { cacheKey } from '../common/js/config.js';
import { validate, getFirstErrMsg } from '../common/js/validator.js';
import validateRules from './js/validateRules.js';
import { authChecker, shareCtrl } from '@/common/js/mmcModule';

/** 注册键盘定位指令 */
registerDirectives();

/** @typedef {{ projuuid: string, edit?: string }} PayeeParams 收款人验证的页面参数 */
const params = /** @type {PayeeParams} */ (utils.getRequestParams());

export default {
    name: 'ReviewPayee',

    components: {
        AddressPicker,
        BankPicker,
        RelationPanel,
        RelationTab,
        mmcButton,
        Uploader,
    },

    data() {
        return {
            /** 是否初始化完成 */
            inited: false,
            project_uuid: params.projuuid,
            isEdit: params.edit === '1',
            /** @type {mmc.review.aided.ID_TYPE} 患者身份证件类型 */
            payee_term: '',
            /** 收款人与患者的关系 */
            type: '',
            /** 收款人姓名 */
            payee_name: '',
            /** 收款人姓名 缓存用于切换区分本人**/
            cache_payee_name: '',
            /** 收款人手机号 */
            phone: '',
            /** 收款人账号 */
            payee_account: '',
            /** 显示用银行卡号，有空格分离 */
            payee_account_display: '',
            /** 收款银行名称 */
            payee_location_name: '',
            /** 收款银行 code */
            payee_location_code: '',
            /** 收款支行名 */
            payee_location_branch: '',
            /** 身份证号码 */
            card_no: '',
            /** @type {Array<{ type: 'id_card'; img: string }>} 身份证图片 */
            card_img: [
                {
                    type: 'id_card',
                    img: '',
                },
            ],
           
            medical_img: [
                {
                    type: 'marry_page',
                    img: '',
                    label: '医疗材料',
                },
            ],
            /** 开户支行所在省市 */
            bank_loc: {
                /** 银行省份 ID */
                province: 0,
                /** 银行省份 */
                province_name: '',
                /** 银行城市 ID */
                city: 0,
                /** 银行城市 */
                city_name: '',
            },
            
            /** @type {mmc.review.payee.BankItemWithGroup[]} 所有的银行信息 */
            allBanks: [],
            /** 是否正在提交中 */
            isSubmitting: false,
            /** 报错信息 */
            errMsg: '',


            /** 患者证件类型与关系是否一致 */
            idTypeAndRelationDisagree: true,
        };
    },

    computed: {
        /**
         * 患者关系为self时, 收款人姓名、身份证禁止编辑
         * @return {boolean}
        */
        inputIsEdit() {
            // if(this.type === 'self') return false;
            return true;
        },

        /**
         * 是否显示身份证号输入框
         * @return {boolean}
         */
        showCardNo() {
            let temp = false
            if(this.payee_term==1){
                temp = true
            }
            return temp;
        },

       
        /**
         * 待提交数据
         * @return {mmc.review.payee.Request}
         */
        dataToSubmit() {
            switch (this.payee_term) {
                
                case 1:
                    return {
                        ...this.dataToSubmitCommon,
                        ...this.dataToSubmitOnlyHuman,
                    };
                case 2 :
                    return {
                        ...this.dataToSubmitCommon,
                    };
            }
        },

        /**
         * 待提交数据（通用）
         * @return {mmc.review.payee.CommonRequest}
         */
        dataToSubmitCommon() {
            const {
                project_uuid,
                payee_term,
                payee_name,
                payee_account,
                payee_location_name,
                payee_location_code,
                payee_location_branch,
                bank_loc,
                card_img,
                medical_img
            } = this;

            return {
                project_uuid,
                payee_term,
                payee_name: payee_name.trim(),
                payee_account: replacetStrTrimAll(payee_account),
                payee_location_name,
                payee_location_code,
                payee_location_branch,
                bank_loc,
                card_img,
                medical_img
            };
        },

       
        dataToSubmitOnlyHuman() {
            const { phone, card_no} = this;

            return {
                phone,
                card_no: replacetStrTrimAll(card_no),
            };
        },

       
    },

    watch: {
        dataToSubmit() {
            if (this.isEdit) return;

            // @ts-ignore
            this.debouncedSaveCachedData();
        },
    },

    created() {
        if (!this.project_uuid) {
            alertMsg('页面参数错误');
            return;
        }



        authChecker.check({ notneedphone: 1 }, () => {
            this.getPayeeInfo();
            this.getAllBanks();

            // @ts-ignore
            this.debouncedSaveCachedData = debounce(this.saveCacheData, 500);

            shareCtrl.init();
        });
    },

    methods: {
        saveCacheData() {
            store.set(cacheKey.payee, this.dataToSubmit);
        },

        /** 读取所有的银行卡列表 */
        getAllBanks() {
            getBankList().then(res => {
                if (res.code === 0 && res.data && res.data.length > 0) {
                    this.allBanks = formatCardList(res.data);
                }
            });
        },

        /**
         * 监听用户名输入
         * @param {InputEvent} e
        */
        userNameChange(e) {
            if (!e.target) return;
            const el = /** @type {HTMLInputElement} */ (e.target);

            this.payee_name = el.value;
            this.cache_payee_name = el.value;
        },

        /**
         * 监听银行卡号输入
         * @param {InputEvent} e
         */
        onPayeeAccountInput(e) {
            if (!e.target) return;
            const el = /** @type {HTMLInputElement} */ (e.target);

            this.payee_account = el.value;
            this.payee_account_display = this.formatPayeeAccount(this.payee_account);

            // 重新校准光标位置，防止在中间位置修改银行卡号时光标漂移
            let pos = el.selectionStart || 0;
            if (pos % 5 === 0) {
                pos += 1;
            }
            this.$nextTick(() => {
                el.setSelectionRange(pos, pos);
            });
        },

        /**
         * 银行开格式化处理
         * @param {string} payee_account 银行卡号
         */
        formatPayeeAccount(payee_account) {
            let payeeAccount = payee_account.replace(/\s/g, '');
            return payeeAccount.replace(
                /(\d{4})(?=\d)/g,
                 '$1 '
            );
        },

        /**
         * 监听银行卡类型变化
         * @param {string} bank_type 银行英文简写
         * @param {string} bank_name 银行中文名称
         */
        onBankTypeChange(bank_type, bank_name) {
            this.payee_location_code = bank_type;
            this.payee_location_name = bank_name;
        },

        async getPayeeInfo() {
            try {
                const res = await getPayeeInfo(this.project_uuid);
                console.log('payee info:', res);
                if (res && res.code === 0 && res.data) {
                    this.handlePayeeInfo(res.data);
                }
            } catch (e) {
                console.log('get payee info error:', e);
            }
        },

        /**
         * 处理获取的收款人信息
         * @param {mmc.review.payee.Response} resData
         */
        handlePayeeInfo(resData) {
            const { payee_info } = resData;
            if(payee_info){
                this.payee_term = payee_info.payee_term;
            }
            

            if (this.isEdit) {
                if (payee_info) {
                    this.updateLocalData(payee_info);
                }
            } else {
                this.readCacheData();
            }

            this.inited = true;
        },

        /** 读取本地缓存数据 */
        readCacheData() {
            /** @type {mmc.review.payee.Request} */
            const data = store.get(cacheKey.payee);
            if (!data) return;

            /** 防止被其他项目的验证信息污染 */
            if (data.project_uuid !== this.project_uuid) return;

            this.updateLocalData(data);
        },

        
        updateLocalData(resData) {
            console.log('update local data with %o', resData);
            const {
                payee_term,
                payee_name,
                phone = '',
                bank_loc,
                payee_account,
                payee_location_name,
                payee_location_branch,
                payee_location_code,
                card_no = '',
                card_img,
                medical_img,
                
            } = resData;

            console.log('payee_location_name', payee_location_name);
            console.log('payee_location_code', payee_location_code);

            

            this.payee_term = payee_term;
            this.cache_payee_name = payee_name;
            this.payee_name = payee_name;
            this.phone = phone;
            this.payee_location_code = payee_location_code;
            this.payee_location_branch = payee_location_branch;
            this.payee_location_name = payee_location_name;
            this.payee_account = payee_account;
            this.bank_loc = bank_loc;
            this.card_no = card_no;

            if (payee_account) {
                this.payee_account_display = this.formatPayeeAccount(payee_account);
            }
            if (card_img && card_img[0] && card_img[0].img) {
                this.card_img[0].img = card_img[0].img;
            }
            if (medical_img && medical_img[0] && medical_img[0].img) {
                this.medical_img[0].img = medical_img[0].img;
            }

            
        },

        /**
         * 关系改变监听函数
         * @param {string} type
         */
        onTypeChange(type) {
            
            this.payee_term = type;
            this.payee_name = this.cache_payee_name;
        },

        /**
         * 当开户支行所在地变化时
         * @param {{ provinceId: number, cityId: number, provinceName: string, cityName: string }} e
         */
        onAddressChange(e) {
            this.bank_loc.province = e.provinceId;
            this.bank_loc.province_name = e.provinceName;
            this.bank_loc.city = e.cityId;
            this.bank_loc.city_name = e.cityName;
        },

        /**
         * 图片上传成功
         * @param {string} url 远程图片地址
         * @param {{ img: string }} item
         */
        onUploadImg(url, item) {
            if (!url) return;
            item.img = url;
        },

        /**
         * 清空图片
         * @param {{ img: string, label: string, type: string }} item
         */
        onDeleteImg(item) {
            console.log('delete', item);
            item.img = '';
        },

        /**
         * 校验用户输入是否正确
         * @returns {boolean}
         */
        checkValid() {
            console.log(this.dataToSubmit,333344)
            const result = validate(this.dataToSubmit, validateRules);
            this.errMsg = getFirstErrMsg(result);
            console.log(this.errMsg,result)
            return result.every(item => item.ok);
        },

        async onSubmit() {
            if (this.checkValid() === false) {
                alertMsg(this.errMsg);
                return;
            }
            console.log(this.dataToSubmit,333)
            // return

            if (this.isSubmitting) return;
            this.isSubmitting = true;

            showLoading('提交中...');
            try {
                const res = await submitPayeeInfo(this.dataToSubmit);
                hideLoading();
                this.isSubmitting = false;

                if (res && res.code === 0) {
                    alertMsg('提交成功', 1500);
                    store.remove(cacheKey.payee);

                    setTimeout(() => {
                        history.go(-1);
                    }, 1500);
                }
            } catch (e) {
                hideLoading();
                this.isSubmitting = false;

                console.log(e);
            }
        },
    },
};
</script>

<style>
@import '../common/style.css';

.icon-card {
    display: inline-block;
    width: 20px;
    height: 14px;
    background: url('img/icon.card.svg') no-repeat center;
    background-size: contain;
}

.icon-hospital {
    display: inline-block;
    width: 16px;
    height: 16px;
    background: url('img/icon.hospital-info.svg') no-repeat center;
    background-size: contain;
}

input.disable {
    pointer-events: none;
}
</style>
