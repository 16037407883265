// @ts-check

/** @typedef {Array<{img: string}>} ImgArr */

/**
 * 判断是否身份证
 * @param {{aided_id_type: mmc.review.aided.ID_TYPE}} item
 * @returns {boolean}
 */
function isIdcard(item) {
    return item.aided_id_type === 1;
}

/**
 * 判断是否出生证
 * @param {{aided_id_type: mmc.review.aided.ID_TYPE}} item
 * @returns {boolean}
 */
function isBirthNo(item) {
    return item.aided_id_type === 2;
}

/**
 * 是否包含地址
 * @param {{province: number, city: number}} item
 * @return {boolean}
 */
function hasAddress(item) {
    return item.province > 0 && item.city > 0;
}

/**
 * 是否上传身份证件图片
 * @param {Array<{ img: string }>} item
 * @returns {boolean}
 */
function hasCardImg(item) {
    return item[0].img !== '';
}


function includeRelationFilter(n) {
    return function isRelationOfType(item) {
        // console.log(item.type, item.payee_term, 11, types)
        // return types.indexOf(item.type) !== -1;

        return item.payee_term === n
    };
}


function excludeRelationFilter(n) {
    return function isRelationOfType(item) {
        // console.log(item, 3334)
        // return types.indexOf(item.type) === -1;
        // alert(item.payee_term === n)
        return item.payee_term !== n
    };
}

/**
 * 生成关系证明图片的判别函数
 *
 * @param {number[]} idxes 这些索引值对应的图片至少有一张必须存在，否则返回 false
 * @returns {(items: Array<{ img: string }>) => boolean}
 */
function hasRelationImg(idxes) {
    return function isRelationImgValid(items) {
        for (let i = 0; i < idxes.length; i++) {
            const idx = idxes[i];
            const item = items[idx] || {};
            if (item.img !== '') {
                return true;
            }
        }

        return false;
    };
}

/**
 * 判断是否包含正确的医院信息
 * @param {string} key
 * @returns { (item: Record<string, string>) => boolean }
 */
function hasHospitalInfo(key) {
    return function(item) {
        return !!item[key];
    };
}

export default [{
        id: 'payee_name',
        filter: excludeRelationFilter(2),
        rules: [
            { required: true, minlength: 2, msg: '请填写正确的收款人真实姓名' },
        ],
    },
    {
        id: 'payee_name',
        filter: includeRelationFilter(2),
        rules: [{
            required: true,
            minlength: 2,
            msg: '请填写正确的对公账户名称',
        }, ],
    },
    {
        id: 'card_no',
        filter: excludeRelationFilter(2),
        rules: [{
            required: true,
            msg: '请填写正确的收款人身份证号码',
        }, ],
    },


    {
        id: 'phone',
        filter: excludeRelationFilter(2),
        rules: [
            { required: true, phone: true, msg: '请输入正确的收款人手机号' },
        ],
    },
    {
        id: 'payee_location_code',
        rules: [{
            required: true,
            msg: '请选择开户银行',
        }, ],
    },
    {
        id: 'payee_account',
        filter: excludeRelationFilter(2),
        rules: [
            { required: true, minlength: 2, msg: '请填写正确的收款人银行卡号' },
        ],
    },
    {
        id: 'payee_account',
        filter: includeRelationFilter(2),
        rules: [
            { required: true, minlength: 2, msg: '请填写正确的对公账户银行卡号' },
        ],
    },
    {
        id: 'bank_loc',
        rules: [{ func: hasAddress, msg: '请选择开户行所在省市' }],
    },
    {
        id: 'payee_location_branch',
        rules: [{
            required: true,
            minlength: 2,
            msg: '请填写正确的开户支行地址',
        }, ],
    },
    {
        id: 'card_img',
        filter: excludeRelationFilter(2),
        rules: [{
            func: hasCardImg,
            msg: '请上传收款人手持身份证图片',
        }, ],
    },
    {
        id: 'card_img',
        filter: includeRelationFilter(2),
        rules: [{
            func: hasCardImg,
            msg: '请上传社会组织法人登记证书/营业执照',
        }, ],
    },
    // {
    //     id: 'relation_img',
    //     filter: includeRelationFilter('family'),
    //     rules: [{
    //             func: hasRelationImg([0, 1, 2]),
    //             msg: '请上传患者和收款人关系证明图片',
    //         },
    //         {
    //             func: hasRelationImg([0]),
    //             msg: '请上传患者户口簿首页图片',
    //         },
    //         {
    //             func: hasRelationImg([1]),
    //             msg: '请上传患者户口簿本人页图片',
    //         },
    //         {
    //             func: hasRelationImg([2]),
    //             msg: '请上传收款人户口簿本人页图片',
    //         },
    //     ],
    // },
    // {
    //     id: 'relation_img',
    //     filter: includeRelationFilter('couple'),
    //     rules: [{
    //         func: hasRelationImg([0]),
    //         msg: '请上传患者和收款人结婚证图片',
    //     }, ],
    // },
];