<template>
    <div class="address-picker">
        <PickerButton
            placeholder="选择开户行（仅限储蓄卡）"
            :value="currentBank.name"
            :error="error"
            class="mt-12"
            @click.native="onClickButton"
        >
        </PickerButton>

        <BankCardModal
            v-if="showModal"
            :bankCardList="banks"
            @change="onChangeBank"
            @close="showModal = false"
        />
    </div>
</template>

<script>
// @ts-check
import PickerButton from '../PickerButton/index.vue';
import BankCardModal from '../BankCardModal/index.vue';

export default {
    name: 'BankPicker',

    components: {
        PickerButton,
        BankCardModal,
    },

    props: {
        /** 银行卡类型 */
        bankType: {
            type: String,
            default: '',
        },

        /** @type {import('vue').PropOptions<mmc.review.payee.BankItemWithGroup[]>} */
        banks: {
            type: Array,
            default() {
                return [];
            },
        },

        error: {
            type: String,
            default: '',
        },
    },

    data() {
        return {
            showModal: false,
        };
    },

    computed: {
        /**
         * 获取当前选中的银行
         * @return {mmc.review.payee.BankItem | { name: string }}
         */
        currentBank() {
            const emptyBank = { name: '' };
            if (!this.bankType) return emptyBank;
            if (this.banks.length <= 0) return emptyBank;

            for (let i = 0; i < this.banks.length; i++) {
                if (this.banks[i].bank === this.bankType) {
                    return this.banks[i];
                }
            }

            return emptyBank;
        },
    },

    methods: {
        onClickButton() {
            this.showModal = true;
        },

        /**
         * 监听银行卡变化
         * @param {{bank_name: string, bank_tag: string}} event
         */
        onChangeBank(event) {
            this.showModal = false;
            this.$emit('change', event.bank_tag, event.bank_name);
        },
    },
};
</script>
