<template>
    <div class="modal-warpper z-1024">
        <div class="mask custom-popup"></div>
        <div class="modal-body">
            <div class="modal-header">
                <div class="laabel-title">
                    开户银行
                    <span class="close_btn" @click="closeModal"></span>
                </div>
                <div class="search-container">
                    <span class="search-icon"></span>
                    <input
                        v-model="keyword"
                        class="search-input"
                        placeholder="请输入银行名称"
                    />
                </div>
            </div>

            <div v-if="renderList" class="modal-list">
                <div
                    v-for="(item, index) in renderList"
                    :key="item.name"
                    class="item-group"
                    @click="onSelectBank(item)"
                >
                    <div
                        v-if="showType(index, renderList, item)"
                        class="item-label"
                    >
                        {{ item.group == 'CY' ? '常用' : item.group }}
                    </div>
                    <div
                        class="item-crad_info"
                        :class="{
                            bottomNo: hideBorder(index, renderList, item),
                        }"
                    >
                        <span class="item_bank_name">{{ item.name }}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// @ts-check
export default {
    name: 'BankCardModal',

    props: {
        /** @type {import('vue').PropOptions<mmc.review.payee.BankItemWithGroup[]>} */
        bankCardList: {
            type: Array,
            default() {
                return [];
            },
        },
        modalPopup: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            keyword: '',
        };
    },

    computed: {
        /**
         * 需要渲染的列表
         * @return {mmc.review.payee.BankItemWithGroup[]}
         */
        renderList() {
            if (!this.keyword) return this.bankCardList;

            return this.bankCardList.filter(
                item => item['name'].indexOf(this.keyword) !== -1
            );
        },
    },

    methods: {
        /**
         * 是否显示群组标题
         * @param {number} index
         * @param {mmc.review.payee.BankItemWithGroup[]} list
         * @param {mmc.review.payee.BankItemWithGroup} currentItem
         */
        showType(index, list, currentItem) {
            if (index === 0) return true;

            return list[index - 1].group !== currentItem.group;
        },

        /**
         * 是否隐藏边界
         * @param {number} index
         * @param {mmc.review.payee.BankItemWithGroup[]} renderList
         * @param {mmc.review.payee.BankItemWithGroup} currentItem
         */
        hideBorder(index, renderList, currentItem) {
            return (
                !renderList[index + 1] ||
                renderList[index + 1].group !== currentItem.group
            );
        },

        closeModal() {
            this.$emit('close');
        },

        /**
         * 选择银行
         * @param {{ name: string, bank: string }} item
         */
        onSelectBank(item) {
            this.$emit('change', {
                bank_name: item.name,
                bank_tag: item.bank,
            });
        },
    },
};
</script>

<style lang="less" scoped>
@cardListH: 600/7.5vh;

.modal-warpper {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
}

.custom-popup {
    height: 100%;
    width: 100%;
    position: fixed;
    z-index: 99;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.6);
}

.modal-body {
    width: 100%;
    position: fixed;
    left: 0;
    bottom: 0;
    background: #fff;
    border-radius: 10px 10px 0 0;
    overflow: hidden;
    transition: 0.4s;
    z-index: 101;
}

.popup-content-show {
    transform: translateY(0);
}
.modal-header {
    padding: 20px 15px;
}
.laabel-title {
    font-size: 18px;
    color: #000;
    line-height: 1;
    text-align: center;
    font-weight: 600;
    margin-bottom: 20px;
    position: relative;
}

.close_btn {
    display: block;
    width: 14px;
    height: 14px;
    background: url('../../img/close.png') no-repeat center;
    background-size: cover;
    position: absolute;
    right: 0;
    top: 50%;
    margin-top: -7px;
}

.search-container {
    background: #f8f8f8;
    border-radius: 23px;
    display: flex;
    align-items: center;
    padding: 9px 10px;
    .search-icon {
        width: 14px;
        height: 14px;
        background: url('../../img/search-icon.png') no-repeat center;
        background-size: cover;
        flex-shrink: 0;
        margin-right: 6px;
    }
    .search-input {
        flex: 1;
        background: none;
        border: none;
        color: #333;
        font-size: 14px;
        line-height: 1;
    }
}

.modal-list {
    height: calc(100vh - 260px);
    overflow: hidden;
    overflow-y: scroll;
    padding-bottom: 10px;
    -webkit-overflow-scrolling: touch;
}

.item-group {
    .item-label {
        padding: 5px 15px;
        background: #ecedee;
        font-size: 14px;
        color: #666;
    }
    .item-crad_info {
        display: flex;
        align-items: center;
        padding: 12px 0;
        margin: 0 15px;
        left: 0;
        border-bottom: 0.5px solid #dddfe2;
    }
    .bottomNo {
        border-bottom: none;
    }
    .item_bank_logo {
        width: 32px;
        height: 32px;
        background-size: contain;
        margin-right: 10px;
        background-repeat: no-repeat;
        background-position: center center;
    }
    .item_bank_name {
        font-size: 16px;
        color: #000;
        line-height: 1;
    }
}

@media screen and (max-width: 360px) {
    .modal-list {
        height: calc(100vh - 180px);
    }
}
</style>
