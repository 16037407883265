<template>
    <div>
        <div
            class="item"
            :class="{ active: activeType === item.type }"
            v-for="item of validItems"
            :key="item.type"
            @click="setActive(item.type)"
        >
            <div class="item-icon" :class="['icon-' + item.typeName]"></div>
            <div class="item-label">{{ item.label }}</div>
        </div>
    </div>
</template>

<script>
import { relationTypes } from '../../js/constants.js';

export default {
    name: 'RelationPanel',

    props: {
        idType: {
            type: Number,
            default: 1,
        },
    },

    data() {
        return {
            activeType: '',
            items: relationTypes,
        };
    },

    computed: {
        /**
         * 获取合法的元素列表
         * @return {Array<{ type: string, label: string }>}
         */
        validItems() {
            

            return this.items;
        },
    },

    created(){
        this.activeType = this.idType
    },

    methods: {
        setActive(type) {
            this.activeType = type;
            // 延迟动画，让用户看到反馈交互
            setTimeout(() => {
                this.$emit('change', type);
            }, 100);
        },
    },
};
</script>

<style scoped>
.item {
    width: 43.2vw;
    height: 24vw;
    margin-right: 4.5vw;
    margin-bottom: 4.5vw;
    background: #fff;
    border: 1px solid #999999;
    float: left;
    border-radius: 4px;
    box-sizing: border-box;
    color: #333;
    font-size: 16px;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
}

.item.active {
    background: #059eff;
    border-color: #059eff;
    color: #fff;
}

.item:nth-child(2n) {
    margin-right: 0;
}

.item-icon {
    width: 50px;
    height: 50px;
    background-repeat: no-repeat;
    background-position: center;
}

.icon-self {
    background-image: url('./img/payee.self.svg');
    background-size: 18px 24px;
    margin-right: 5px;
}

.active .icon-self {
    background-image: url('./img/payee.self-white.svg');
}

.icon-family {
    background-image: url('./img/payee.family.svg');
    background-size: 45px 40px;
    margin-right: 5px;
}

.active .icon-family {
    background-image: url('./img/payee.family-white.svg');
}

.icon-couple {
    background-image: url('./img/payee.couple.svg');
    background-size: 37px 37px;
    margin-right: 5px;
}

.active .icon-couple {
    background-image: url('./img/payee.couple-white.svg');
}

.icon-inpatient {
    background-image: url('./img/payee.hospital.svg');
    background-size: 33px 33px;
    margin-right: 5px;
}

.active .icon-inpatient {
    background-image: url('./img/payee.hospital-white.svg');
}
</style>
