// @ts-check

/**
 * 格式化银行列表
 * @param {mmc.review.payee.BankGroup[]} list
 * @return {mmc.review.payee.BankItemWithGroup[]}
 */
export function formatCardList(list) {
    /** @type {mmc.review.payee.BankItemWithGroup[]} */
    let result = [];
    list.forEach(item => {
        item.row.forEach(rowItem => {
            const groupItem = { ...rowItem, group: item.group };
            result.push(groupItem);
        });
    });
    return result;
}
